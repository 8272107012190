/**
 * Checks if a function is callable.
 */
export const isCallable = func => 'function' === typeof func;

/**
 * Assign polyfill from the mdn.
 */
export const assign = (target, ...others) => {
  /* istanbul ignore else */
  if (isCallable(Object.assign)) {
    return Object.assign(target, ...others);
  }

  /* istanbul ignore next */
  if (null == target) {
    throw new TypeError('Cannot convert undefined or null to object');
  }

  /* istanbul ignore next */
  const to = Object(target);
  /* istanbul ignore next */
  others.forEach(arg => {
    // Skip over if undefined or null
    if (null != arg) {
      Object.keys(arg).forEach(key => {
        to[key] = arg[key];
      });
    }
  });
  /* istanbul ignore next */
  return to;
};

/**
 * Checks if the values are either null or undefined.
 */
export const isNullOrUndefined = (...values) => values.every(value => null === value || value === undefined);
