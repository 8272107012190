import { prefixAPI } from '../resource/api'

import {
  createResource,
  postResource,
} from '../resource/resource'

const MARKET_MODEL = 'market/'

export const PAYMENTS_EXPORT = prefixAPI('payments/export/', MARKET_MODEL)
export const EXCHANGE_EXPORT = prefixAPI('exchanges/export/', MARKET_MODEL)

/* MARKET */
const CURRENCIES = prefixAPI('currencies/{?merchant}', MARKET_MODEL)
const CURRENCIES_LIST = prefixAPI('currencies/list/{?type,for_buy,for_sale}', MARKET_MODEL)
const CURRENCIES_CARDS = prefixAPI('currencies/cards/', MARKET_MODEL)
const CURRENCIES_FIAT_CARDS = prefixAPI('currencies/cards/fiat/', MARKET_MODEL)
const CURRENCIES_STABLE_CARDS = prefixAPI('currencies/cards/stable/', MARKET_MODEL)
const PAIRS = prefixAPI('currency-pairs/', MARKET_MODEL)
const WALLETS = prefixAPI('wallets/', MARKET_MODEL)
const ALIASES = prefixAPI('wallets/aliases/', MARKET_MODEL)
const DELETE_WALLET = prefixAPI('wallets/delete/', MARKET_MODEL)
const TRANSFER = prefixAPI('transfers/', MARKET_MODEL)
const TRANSFER_COMMISSION = prefixAPI('transfers/config/', MARKET_MODEL)
const TO_BALANCE = prefixAPI('transfers/to-balance/', MARKET_MODEL)
const TRANSACTIONS = prefixAPI('transactions/', MARKET_MODEL)
const CHART_SELECTS = prefixAPI('currencies/chart/selects/', MARKET_MODEL)
const CHART_RATES = prefixAPI('currencies/chart/rates/{?pair,time}', MARKET_MODEL)
const MARKET_BANKS = prefixAPI('banks/list/{?currency}', MARKET_MODEL)
const MARKET_PROVIDERS = prefixAPI('payments/providers/list/', MARKET_MODEL)
const REPLENISH = prefixAPI('payments/replenish/', MARKET_MODEL)
const REPLENISH_CALCULATE = prefixAPI('payments/replenish/calculate/', MARKET_MODEL)
const REPLENISH_COMMISSION = prefixAPI('payments/replenish/commission/{?currency}', MARKET_MODEL)
const EXCHANGE = prefixAPI('exchanges/', MARKET_MODEL)
const EXCHANGE_SELECTS = prefixAPI('exchanges/selects/', MARKET_MODEL)
const TRANSACTIONS_SELECTS = prefixAPI('payments/selects/', MARKET_MODEL)
const EXCHANGE_CALCULATE = prefixAPI('exchanges/calculate/', MARKET_MODEL)
const EXCHANGE_CONFIG = prefixAPI('exchanges/config/{currency}/', MARKET_MODEL)
const SALES = prefixAPI('sales/', MARKET_MODEL)
const SALES_FIAT_QUOTE = prefixAPI('sales/fiat/quote/', MARKET_MODEL)
const SALES_FIAT_PAYMENT = prefixAPI('sales/fiat/payment/', MARKET_MODEL)
const SALES_CALCULATE = prefixAPI('sales/calculate/', MARKET_MODEL)
const SALES_CONFIG = prefixAPI('sales/config/{currency}/', MARKET_MODEL)
const WITHDRAW = prefixAPI('payments/withdraw/', MARKET_MODEL)
const WITHDRAW_CALCULATE = prefixAPI('payments/withdraw/calculate/', MARKET_MODEL)
const WITHDRAW_COMMISSION = prefixAPI('payments/withdraw/commission/{?currency,network}', MARKET_MODEL)

/* MARKET */

export const getCurrenciesAPI = createResource(
  CURRENCIES
)
export const getCurrenciesListAPI = createResource(
  CURRENCIES_LIST
)

export const getPairsAPI = createResource(
  PAIRS
)

export const getMarketBanksAPI = createResource(
  MARKET_BANKS
)

export const getPaymentProvidersAPI = createResource(
  MARKET_PROVIDERS
)

export const currenciesCardAPI = createResource(
  CURRENCIES_CARDS
)

export const currenciesFiatCardAPI = createResource(
  CURRENCIES_FIAT_CARDS
)

export const currenciesStableCardAPI = createResource(
  CURRENCIES_STABLE_CARDS
)

export const getAliasesAPI = createResource(
  ALIASES
)

export const getWalletsAPI = createResource(
  WALLETS
)

export const walletsAPI = createResource(
  WALLETS,
  postResource
)

export const deleteWalletAPI = createResource(
  DELETE_WALLET,
  postResource
)

export const transferAPI = createResource(
  TRANSFER,
  postResource
)

export const transferCommissionAPI = createResource(
  TRANSFER_COMMISSION
)

export const toBalanceAPI = createResource(
  TO_BALANCE,
  postResource
)

export const getTransactionsAPI = createResource(
  TRANSACTIONS
)

export const transactionsAPI = createResource(
  TRANSACTIONS,
  postResource
)

export const chartRatesAPI = createResource(
  CHART_RATES
)

export const chartSelectsAPI = createResource(
  CHART_SELECTS
)

// export const getPaymentsAPI = createResource(
//   PAYMENTS
// )

export const replenishAPI = createResource(
  REPLENISH,
  postResource
)

export const replenishCalculateAPI = createResource(
  REPLENISH_CALCULATE,
  postResource
)

export const replenishCommissionAPI = createResource(
  REPLENISH_COMMISSION
)

export const salesConfigAPI = createResource(
  SALES_CONFIG
)

export const exchangeAPI = createResource(
  EXCHANGE,
  postResource
)

export const exchangeConfigAPI = createResource(
  EXCHANGE_CONFIG
)

export const exchangeSelectsAPI = createResource(
  EXCHANGE_SELECTS
)

export const salesAPI = createResource(
  SALES,
  postResource
)
export const salesCalculateAPI = createResource(
  SALES_CALCULATE,
  postResource
)

export const salesFiatQuoteAPI = createResource(
  SALES_FIAT_QUOTE,
  postResource
)
export const salesFiatPaymentAPI = createResource(
  SALES_FIAT_PAYMENT,
  postResource
)

export const transactionsSelectsAPI = createResource(
  TRANSACTIONS_SELECTS
)

export const exchangeCalculateAPI = createResource(
  EXCHANGE_CALCULATE,
  postResource
)

export const withdrawAPI = createResource(
  WITHDRAW,
  postResource
)

export const withdrawCalculateAPI = createResource(
  WITHDRAW_CALCULATE,
  postResource
)

export const withdrawCommissionAPI = createResource(
  WITHDRAW_COMMISSION
)
